import React from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField
} from "react-admin";

export const JobList = props => (
  <List {...props} sort={{ field: "id", order: "DESC" }}>
    <Datagrid>
      <TextField source="id" />
      <DateField source="created_at" label="Time" showTime />
      <BooleanField source="success" />
      <TextField source="error" sortable={false} />
      <TextField source="task" />
      <TextField source="trigger" />
      <TextField source="router" />
      <TextField source="selector" />
      <TextField source="executor" />
      <TextField source="result" sortable={false} />
    </Datagrid>
  </List>
);
