import React from "react";
import { Admin, Resource, fetchUtils } from "react-admin";
//import { ListGuesser } from "react-admin";
import { createBrowserHistory as createHistory } from "history";
import simpleRestProvider from "ra-data-simple-rest";
import WorkIcon from "@material-ui/icons/Work";

import Dashboard from "./dashboard";
import authProvider from "./authProvider";
import { JobList } from "./jobs";

const history = createHistory();

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
  "https://stat.zhaoxh.cn/api",
  httpClient
);

const App = () => (
  <Admin
    dashboard={Dashboard}
    history={history}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource name="jobs" list={JobList} icon={WorkIcon} />
  </Admin>
);

export default App;
